import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AnalyticsSnippet } from '@segment/analytics-next';

import 'core-js/stable';

import { addAmplitudeToConfigHeader } from './resources/amplitude/addAmplitudeConfigToHeader';
import { initGoogleMaps } from './utils/external-libs/googleMaps';
import { initSegment } from './utils/external-libs/segment';
import { setupSentry } from './utils/external-libs/sentry';
import lazyWithRetry from './utils/lazyWithRetry';

const HomeOwnerFlowWidget = lazyWithRetry(() => import('./components/InitApp/HomeOwnerFlowWidget'));
const LeadFormInit = lazyWithRetry(() => import('../leadForm/LeadFormInit'));

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
    GOOGLE_RESERVE?: {
      conversion_tracking_url: string;
      partner_id: string;
    };
    SENTRY?: {
      enabled: boolean;
      environment: string;
    };
    SEGMENT?: {
      enabled: boolean;
      jsKey: string;
      cdnEndpointUrl: string;
      trackingEndpointUrl: string;
    };
    GOOGLE_MAPS_API_KEY: string;
    AMPLITUDE_ENDPOINT_URL?: string;
    BOOK_HOUSECALL_URL?: string;
    Cypress: unknown;

    HC_ENV_REVISION: string;
    HC_ENV_NODE_ENV: string;
    HC_ENV_APP_ENVIRONMENT: string;
    RECAPTCHA_ENTERPRISE_KEY: string;
    HOUSECALL_SERVER_URL: string;
    STRIPE_PUBLISHABLE_KEY: string;
  }
}

window.BOOK_HOUSECALL_URL = BOOK_HOUSECALL_URL;
window.AMPLITUDE_ENDPOINT_URL = AMPLITUDE_ENDPOINT_URL;
window.GOOGLE_MAPS_API_KEY = GOOGLE_MAPS_API_KEY;
window.SENTRY = SENTRY;
window.SEGMENT = SEGMENT;
window.RECAPTCHA_ENTERPRISE_KEY = RECAPTCHA_ENTERPRISE_KEY;
window.HOUSECALL_SERVER_URL = HOUSECALL_SERVER_URL;
window.STRIPE_PUBLISHABLE_KEY = STRIPE_PUBLISHABLE_KEY;
window.GOOGLE_RESERVE = GOOGLE_RESERVE;

const shouldRenderLeadCapture = () => {
  return window.location.href.search('/book/') === -1 && window.location.href.search('/lead-form/') !== -1;
};

const mountComponents = () => {
  const root = document.getElementById('root');
  ReactDOM.render(
    <Suspense fallback={<div />}>
      <HomeOwnerFlowWidget />
    </Suspense>,
    root
  );
};

const mountLeadFormComponents = () => {
  const root = document.getElementById('root');
  ReactDOM.render(
    <Suspense fallback={<div />}>
      <LeadFormInit />
    </Suspense>,
    root
  );
};

const initNewApp = () => {
  initSegment();
  initGoogleMaps();
  setupSentry();
  addAmplitudeToConfigHeader();
  mountComponents();
};

const initLeadCapture = () => {
  initSegment();
  initGoogleMaps();
  setupSentry();
  addAmplitudeToConfigHeader();
  mountLeadFormComponents();
};

(function startOLB() {
  if (shouldRenderLeadCapture()) {
    initLeadCapture();
  } else {
    initNewApp();
  }
})();
